import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { useMyBalanceChangedSubscription } from './graphql/MyBalanceChangedSubscription.generated';
import useCashierCurrencyChange from './useCashierCurrencyChange';
import useCurrencyChangeListener from './useCurrencyChangeListener';
import BalanceProps from '../BalanceProps';
import getLanguage from '@/client/utils/getLanguage';
import getUserBalance from '@/server/graphql/getUserBalance';
import getDisplayAmount from '@/shared/utils/getDisplayAmount';

const useBalance = ({
  initialBalance,
  initialCurrency,
  initialCurrencyConfig,
}: BalanceProps) => {
  const { refresh } = useRouter();
  const language = getLanguage();
  const [balance, setBalance] = useState(initialBalance);
  const [selectedCurrency, setCurrency] = useState(initialCurrency);
  const [currencyConfig, setConfig] = useState(initialCurrencyConfig);
  const onChange = async () => {
    const { config, currency, totalBalance } = await getUserBalance();
    if (totalBalance !== undefined) {
      setBalance(totalBalance);
      setCurrency(currency);
      setConfig(config);
    }
    refresh();
  };

  useMyBalanceChangedSubscription({
    onData: (data) => {
      const { changedBalance } = data.bankingMyBalanceChanged ?? {};

      if (
        !changedBalance?.totalBalance ||
        changedBalance.currency !== selectedCurrency
      ) {
        return;
      }

      if (changedBalance.totalBalance) {
        setBalance(changedBalance.totalBalance);
      }
    },
  });

  useCashierCurrencyChange({
    onChange,
  });

  useCurrencyChangeListener({ onChange });

  return getDisplayAmount(currencyConfig, balance, selectedCurrency, language);
};

export default useBalance;
